import React from 'react';
import Masonry from 'react-masonry-css';
import './RouteWall.scss';
import { ProjectUpdateSkeleton } from '../ProjectUpdate/ProjectUpdateSkeleton';

const breakpointColumnsObj = {
	default : 4,
	1599 : 3,
	991 : 2,
	767 : 1
};

const skeleton = [
	<ProjectUpdateSkeleton key={0} animate={true}/>,
	<ProjectUpdateSkeleton key={1} animate={true}/>,
	<ProjectUpdateSkeleton key={2} animate={true}/>,
	<ProjectUpdateSkeleton key={3} animate={true}/>,
	<ProjectUpdateSkeleton key={4} animate={true}/>,
	<ProjectUpdateSkeleton key={0} animate={true}/>,
	<ProjectUpdateSkeleton key={5} animate={true}/>,
	<ProjectUpdateSkeleton key={6} animate={true}/>,
	<ProjectUpdateSkeleton key={7} animate={true}/>,
	<ProjectUpdateSkeleton key={8} animate={true}/>,
	<ProjectUpdateSkeleton key={9} animate={true}/>,
	<ProjectUpdateSkeleton key={10} animate={true}/>,
	<ProjectUpdateSkeleton key={11} animate={true}/>,
	<ProjectUpdateSkeleton key={13} animate={true}/>
];

export default React.memo(() => (
	<div className="container-fluid py-2 h-100">
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="wall-grid"
				columnClassName="wall-column">
					{ skeleton }
			</Masonry>
		</div>
	)
);