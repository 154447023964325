import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from '../Sidebar/MenuItem/MenuItem';
//import { MenuHeader } from '../Sidebar/MenuHeader/MenuHeader';
//import { MenuDropdown } from '../Sidebar/MenuDropdown/MenuDropdown';

import { library } from '@fortawesome/fontawesome-svg-core';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { faRulerCombined } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faBox } from '@fortawesome/free-solid-svg-icons';

library.add(faHome);
library.add(faUsers);
library.add(faSitemap);
library.add(faHandshake);
library.add(faTasks);
library.add(faRulerCombined);
library.add(faMapMarkerAlt);
library.add(faBox);

export const MenuLinks = React.memo(function(){
	const authLevel = useSelector(state => state.auth.user.type);
	
	const links = useMemo( () => {
		const childs = [
		];
		
		
		if(void 0 !== authLevel){
			if(authLevel > 1){
				childs.push(<MenuItem text="menu.home" key="/" to="/" exact="true" icon="home"/>);
			}
			
			if(authLevel > 0){
				childs.push(<MenuItem text="menu.solicitudes" key="/solicitudes" to="/solicitudes" icon="tasks"/>);
			}
			
			switch(parseInt(authLevel)){
				case 1:
					childs.push(<MenuItem text="menu.users" key="/abm/users" to="/abm/users" icon="users"/>);
					childs.push(<MenuItem text="menu.departamentos" key="/abm/departamentos" to="/abm/departamentos" icon="sitemap"/>);
					childs.push(<MenuItem text="menu.destinos" key="/abm/destinos" to="/abm/destinos" icon="map-marker-alt"/>);
					childs.push(<MenuItem text="menu.unidades" key="/abm/unidades" to="/abm/unidades" icon="ruler-combined"/>);
					break;
				case 2:
					childs.push(<MenuItem text="menu.proveedores" key="/abm/proveedores" to="/abm/proveedores" icon="handshake"/>);
					childs.push(<MenuItem text="menu.destinos" key="/abm/destinos" to="/abm/destinos" icon="map-marker-alt"/>);
					childs.push(<MenuItem text="menu.unidades" key="/abm/unidades" to="/abm/unidades" icon="ruler-combined"/>);
					childs.push(<MenuItem text="menu.pedidos" key="/abm/pedidos" to="/abm/pedidos" icon="box"/>);
					break;
				default:
					break;
			}
		}
		return childs;
	}, [authLevel]);
	
	
	
	return (
		<>
			{ links }
		</>
	);
});