import React, { useState } from 'react';
import { SkeletonBlock } from '../SkeletonBlock/SkeletonBlock';
import "./ProjectUpdate.scss";

function getRndInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1) ) + min;
}

export const ProjectUpdateSkeleton = React.memo(function({animate}){
	
	const [height, ] = useState(() => getRndInteger(21, 60));

	return  <div className={`project-update${ animate ? ' entering' : ''}`}>
				<div className="project-update-header">
					<div className="project-update-user-photo theme-dark ghostLoad">
						
					</div>
					<div className="project-update-header-info">	
						<span className="project-update-project">
							<SkeletonBlock width="80px" height="25px"/>
						</span>
						<span className="project-update-user">
							<SkeletonBlock width="50px" height="19px"/>
						</span>
						<span className="project-update-created">
							<SkeletonBlock width="60px" height="16px"/>
						</span>
					</div>
				</div>
				<div className="project-update-body">
					<p>
						<SkeletonBlock width="100%" height={height}/>
					</p>
				</div>
			</div>;
});