import { HTTPClient } from '../HTTPClient';
import config from '../../config';
import { Request } from '../Request';
import { Response } from '../Response';
import store from '../../Store';
import { setDict, deleteDict, setManyKeys, deleteManyKeys } from '../../Store/actions/dictionary';
import { toastr } from 'react-redux-toastr';
import { getLang } from '../Lang';

const http = new HTTPClient();

const rawResponseHooks = new Set();
const errorResponseHooks = new Set();
const errorRequestHooks = new Set();
const successResponseHooks = new Set();

export const addRawResponseHook = function(hook){ rawResponseHooks.add(hook); };
	
export const removeRawResponseHook = function(hook){ rawResponseHooks.delete(hook); }
	
export const addErrorRequestHook = function(hook){ errorRequestHooks.add(hook); }
	
export const removeErrorRequestHook = function(hook){ errorRequestHooks.delete(hook); }
	
export const addErrorResponseHook = function(hook){ errorResponseHooks.add(hook); }
	
export const removeErrorResponseHook = function(hook){ errorResponseHooks.delete(hook); }
	
export const addSuccessResponseHook = function(hook){ successResponseHooks.add(hook); }
	
export const removeSuccessResponseHook = function(hook){ successResponseHooks.delete(hook); }
	
const cacheableKeys = new Map();

const searchDictionary = function(dictionary, item){
	
	if(dictionary.size === 0 || item === null || void 0 === item)
		return item;
	
	if(Array.isArray(item)){
		for(let i = 0, l = item.length; i < l; i++){
			item[i] = searchDictionary(dictionary, item[i]);	
		}
		return item;
	}
	
	if((typeof item !== 'string' && !(item instanceof String)) || !item.startsWith(config.dictKey))
		return item;
	
	const dictName = item.split(config.dictKey)[1];
	
	if(!dictionary.has(dictName))
		return item;
	
	item = dictionary.get(dictName);
	
	for(let key in item){
		
		if(Array.isArray(item[key])){
			item[key] = searchDictionary(dictionary, item[key]);
		}else if((typeof item[key] !== 'string' && !(item[key] instanceof String)) || !item[key].startsWith(config.dictKey))
			continue;
		
		const subDictName = item[key].split(config.dictKey)[1];
		
		if(!dictionary.has(subDictName))
			continue;
		
		item[key] = dictionary.get(subDictName);
		
		for(let subKey in item[key])
			item[key][subKey] = searchDictionary(dictionary, item[key][subKey]);
	}
	
	return item;
};

const handleDictionary = function(returnItems, r){
	if('removed' in r){
		const removedKeys = Object.keys(r.removed);
		const removedKeysLength = removedKeys.length;
				
		if(removedKeysLength > 0){
			
			for(let i = 0; i < removedKeysLength; ++i){
				const key = removedKeys[i];
				if(cacheableKeys.has(key)){
					if(cacheableKeys.get(key) != null)
						clearTimeout(cacheableKeys.get(key));
					cacheableKeys.delete(key);
				}
			}
			
			if(removedKeysLength === 1){
				store.dispatch(deleteDict(removedKeys[0]));
			}else{
				store.dispatch(deleteManyKeys(removedKeys))
			}
		}
	}
	
	if(!('dict' in r))
		return;
	
	const dictionaryKeys = Object.keys(r.dict);
	const dictionaryKeysLength = dictionaryKeys.length;
	
	if(dictionaryKeysLength > 0){
		const newKeysOnTheBlock = {};
		
		for(let i = 0; i < dictionaryKeysLength; ++i){
			const key = dictionaryKeys[i];
			const entry = r.dict[key];
			
			if(entry === null || entry === void 0)
				continue;
			
			const dictionaryIndex = key.split('/')[0];
			const time = config.dictionaryIndexes[dictionaryIndex];
			
			if(time > 0){
				const timerID = setTimeout(() => {
					cacheableKeys.delete(key);
				}, time * 60 * 1000);
				
				if(cacheableKeys.has(key))
					clearTimeout(cacheableKeys.get(key));
				
				cacheableKeys.set(key, timerID);
			}else{
				cacheableKeys.set(key, null);
			}
			newKeysOnTheBlock[key] = entry;
		}
		
		const newKeys = Object.keys(newKeysOnTheBlock);
		const newKeysLength = newKeys.length
		
		if(newKeysLength > 0){
			if(newKeysLength === 1){
				const key = newKeys[0];
				store.dispatch(setDict(newKeysOnTheBlock[key], key));
			}else{
				store.dispatch(setManyKeys(newKeysOnTheBlock));
			}
		}
	}
	
	if(!returnItems) return;
	
	const dictionary = new Map();
	
	const dict = store.getState().dictionary;
	
	for(let key in dict)
		dictionary.set(key, { ...dict[key] });
	
	if('items' in r)
		r.items = r.items.map(item => searchDictionary(dictionary, item));
		
	if('item' in r)
		r.item = searchDictionary(dictionary, r.item);
};

const handleError = function(silent = false, e){
	if(!silent)
		errorRequestHooks.forEach(hook => {
			hook(e);
		});
	return new Response(false);
};

const handleResponse = function(returnItems, silent = false, r){
	rawResponseHooks.forEach(hook => {
		hook(r);
	});
		
	return r.json()
	.then( 
		r => {
			if(!r.success){
				if(!silent)
					errorResponseHooks.forEach(hook => {
						hook(r);
					});
				return r;
			}
			
			handleDictionary(returnItems, r);
			
			if(!silent)
				successResponseHooks.forEach(hook => {
					hook(r);
				});
			return r;
		},
		e => {
			return new Response(false);
		}
	)
};

const getCacheedKeys = function(endpoint){
	const dictKeys = [ ...cacheableKeys.keys()];
	const newDict = {};
	const finalDict = [];
	
	for(const dictKey of dictKeys){
		const [entity, _id] = dictKey.split('/');
		
		if(!(entity in newDict))
			newDict[entity] = [];
		
		newDict[entity].push(_id);
	}
	
	for(const key in newDict){
		finalDict.push(`${key}/${newDict[key].join('.')}`);
	}
	
	return finalDict.join();
};

const nooper = r => r;

const _insertSucess = function(response){
	if(response.success){
		getLang(store.getState().lang)
		.then(lang => { toastr.success(lang['success.insert'])}, nooper );
	}
	return response;
};

const _updateSuccess = function(response){
	if(response.success){
		getLang(store.getState().lang)
		.then(lang => { toastr.success(lang['success.update'])}, nooper );
	}
	return response;
};

const _removeSucess = function(response){
	if(response.success){
		getLang(store.getState().lang)
		.then(lang => { toastr.success(lang['success.remove'])}, nooper );
	}
	return response;
};

export class API{
	
	_delete(endpoint = '/', query = {}, options = {}, responseHandler = nooper, silent = false){
		const controller = new AbortController();
		options.signal = controller.signal;
		options.credentials = 'include'; 
		
		return new Request(
			http.delete(`${this.endpoint}${endpoint}`, query, options )
			.then(r => handleResponse(this.returnItems, silent, r), e => handleError(silent, e))
			.then(responseHandler),
			controller
		);
	}
	
	_get(endpoint = '/', query = {}, options = {}, responseHandler = nooper, silent = false){
		const controller = new AbortController();
		options.signal = controller.signal;
		options.credentials = 'include';
		
		return new Request(
			http.get(`${this.endpoint}${endpoint}`, query, options )
			.then(r => handleResponse(this.returnItems, silent, r), e => handleError(silent, e))
			.then(responseHandler),
			controller
		);
	}
	
	_post(endpoint = '/', query = {}, body = {}, options = {}, responseHandler = nooper, silent = false){
		const controller = new AbortController();
		options.signal = controller.signal;
		options.credentials = 'include';
		
		return new Request(
			http.post(`${this.endpoint}${endpoint}`, query, body, options )
			.then(r => handleResponse(this.returnItems, silent, r), e => handleError(silent, e))
			.then(responseHandler),
			controller
		);
	}
	
	_put(endpoint = '/', query = {}, body = {}, options = {}, responseHandler = nooper, silent = false){
		const controller = new AbortController();
		options.signal = controller.signal;
		options.credentials = 'include';
		
		return new Request(
			http.put(`${this.endpoint}${endpoint}`, query, body, options )
			.then(r => handleResponse(this.returnItems, silent, r), e => handleError(silent, e))
			.then(responseHandler),
			controller
		);
	}
	
	constructor(endpoint = '', returnItems = false){
		this.endpoint = `${config.apiURL}${endpoint}`;
		this.returnItems = returnItems;
	}
	
	/*addRequestTransformers(transformer){
		http.requestTransformers.add(transformer);
	}
	
	removeRequestTransformer(transformer){
		http.requestTransformers.delete(transformer);
	}*/
	
	searchMine(query = {}, silent = false){
		//query.$dict = getCacheedKeys(this.endpoint);
		return this._get('/my', query, undefined, undefined, silent);
	}
	
	search(query = {}, silent = false){
		//query.$dict = getCacheedKeys(this.endpoint);
		return this._get('/search', query, undefined, undefined, silent);
	}
	
	countMine(query = {}, silent = false){
		return this._get('/myCount', query, undefined, undefined, silent);
	}
	
	count(query = {}, silent = false){
		return this._get('/count', query, undefined, undefined, silent);
	}
	
	getSingle(id = '1', silent = false){
		return this._get(`/id/${id}`, undefined, undefined, undefined, silent);
	}
	
	getSelf(silent = false){
		return this._get('/', undefined, undefined, undefined, silent);
	}

	insert(body = {}){
		return this._post('/', {}, body, undefined, _insertSucess);
	}
	
	update(id = '1', body = {}){
		return this._put(`/id/${id}`, {}, body, undefined, _updateSuccess);
	}
	
	updateSelf(body){
		return this._put(`/`, {}, body, undefined, _updateSuccess);
	}
	
	remove(id = '1'){
		return this._delete(`/id/${id}`, undefined, undefined, _removeSucess);
	}
}

const instances = {};
const instancesWithItems = {};

export const getInstance = function(endpoint, returnItems = false){
	let instancePool = returnItems ? instancesWithItems : instances;
	
	if(!(endpoint in instancePool))
		instancePool[endpoint] = new API(endpoint, returnItems);
	
	return instancePool[endpoint];
}