import './MainContent.css';
import { useSelector } from 'react-redux';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useHistory, Switch, Route, Redirect } from "react-router-dom";
import { LazyLoader } from "../LazyLoader/LazyLoader";
import LoginSkeleton from "../RouteLogin/LoginSkeleton";
import ABMSkeleton from "../RouteABM/ABMSkeleton";
import ABMUpdateSkeleton from "../RouteABMUpdate/ABMUpdateSkeleton";
import ABMDeleteSkeleton from "../RouteABMDelete/ABMDeleteSkeleton";
import MyUserSkeleton from "../RouteMyUser/MyUserSkeleton";
import WallSkeleton from "../RouteWall/WallSkeleton";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";
import { Modal } from "../Modal/Modal";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);

export const MainContent = React.memo(function(){
	const renderArea = useRef(null);
	
	const history = useHistory();
	const location = useLocation();
	const background = location.state && location.state.background;
	
	const isLogged = useSelector(state => state.auth.logged);
	const authLevel = useSelector(state => state.auth.user.type);
	const [prevPath, setPrevPath] = useState(location.pathname);
	
	const goBack = useCallback(e => {
		if(history.length === 0){
			history.push("/");
		}else{
			history.goBack();
		}
	}, [history]);

	useEffect(() => {
		if(background || prevPath === location.pathname) return;
		
		renderArea.current.scrollTop = 0;
		setPrevPath(location.pathname);
	}, [prevPath, background, location.pathname]);
	
	const routes = useMemo(() => {
		
		const r = [<Route key="404" path="/404">
			<ErrorScreen title={"notfound.error.title"} 
				description={"notfound.error.description"} 
				action={"notfound.error.action"}/>
		</Route>];
		
		if(!isLogged){			
			r.push(<Route key="restaurar" path="/restaurarcontrasena/:jwt">
				<LazyLoader component={() => import("../RouteRestorePassword/RouteRestorePassword")} fallback={<LoginSkeleton/>} />
			</Route>);
			
			r.push(<Route key="olvide" path="/olvidemicontrasena">
				<LazyLoader component={() => import("../RouteForgotPassword/RouteForgotPassword")} fallback={<LoginSkeleton/>} />
			</Route>);
			
			r.push(<Route key="login" path="/" exact={true}>
				<LazyLoader component={() => import("../RouteLogin/RouteLogin")} fallback={<LoginSkeleton/>} />
			</Route>);
							
			r.push(
				<Route key="matchAll" path="*">
					<Redirect to={{pathname: "/404"}}/>
				</Route>
			);
			return r;
		}
		
		const authL = parseInt(authLevel || 0);
		
		if(authL === 1){
			r.push(<Route key="root" path="/" exact={true}>
				<Redirect to={{pathname: "/abm/users"}}/>
			</Route>);
		}
		
		if(authL === 1 || authL === 2){
			r.push(<Route key="abm" path="/abm/:table">
				<LazyLoader component={() => import("../RouteABM/RouteABM")} fallback={<ABMSkeleton/>}/>
			</Route>);
		}
		
		if(authL > 0){	
			r.push(
				<Route key="myuser" path="/miusuario" >
					<LazyLoader component={() => import("../RouteMyUser/RouteMyUser")} fallback={<MyUserSkeleton/>} />
				</Route>
			);
			
			r.push(<Route key="solicitudes" path="/solicitudes">
				<LazyLoader component={() => import("../Solicitudes/Solicitudes")} fallback={<ABMSkeleton/>}/>
			</Route>);
			
			r.push(<Route key="abmupdate" path="/abmupdate/:table/:id">
				<LazyLoader component={() => import("../RouteABMUpdate/RouteABMUpdate")} fallback={<ABMUpdateSkeleton/>}/>
			</Route>);
			
			r.push(<Route key="abmdelete" path="/abmdelete/:table/:id">
				<LazyLoader component={() => import("../RouteABMDelete/RouteABMDelete")} fallback={<ABMDeleteSkeleton/>}/>
			</Route>);
		}
		
		if(authL > 1){
			r.push(<Route key="pedidos" path="/pedidos/:solicitud">
				<LazyLoader component={() => import("../Pedidos/Pedidos")} fallback={<ABMSkeleton/>}/>
			</Route>);
			
			r.push(<Route key="pedidoupdate" path="/pedidoupdate/:solicitud/:id">
				<LazyLoader component={() => import("../Pedidos/PedidoUpdate")} fallback={<ABMUpdateSkeleton/>}/>
			</Route>);
			
			r.push(
				<Route key="wall" path="/" exact={true}>
					<LazyLoader component={() => import("../RouteWall/RouteWall")} fallback={<WallSkeleton/>} />
				</Route>
			);
		}
		
		return r;
	}, [isLogged, authLevel]);
	
	return (
		<div ref={renderArea} className="main-content">
			<Switch location={background || location}>
				{ routes }
			</Switch>
			{background && 
				<Modal outCallback={goBack} iconButton="times">
					<Switch>
						{ routes }
					</Switch>
				</Modal>
			}
		</div>
	);
});