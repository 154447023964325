const object = {
	dictKey : "dict://",
	reCaptcha : "6LfaqMAUAAAAAA3pzxGRa_Qsxvqavdd9W1xHAWGh",
	localstorageKey : "spedes1",
	endpoints: Object.freeze({		
		auth : 'auth',
		users : 'users',
		departamentos : 'departamentos',
		proveedores : 'proveedores',
		solicitudes : 'solicitudes',
		pedidos : 'pedidos',
		unidades : 'unidades',
		destinos : 'destinos',
	}),
	dictionaryIndexes : Object.freeze({
		users : 0,
		departamentos : 0,
		proveedores : 0,
		solicitudes : 0,
		pedidos : 0,
		unidades : 0,
		destinos : 0,
	})
};

switch(process.env.NODE_ENV){
	case 'production':
		object.apiURL = "http://sp.sistemas.nixel.com.ar/api/v1/";
		object.uploadsURL = "/uploads/";
		break;
	default:
		object.apiURL = "http://localhost/nixelsp/v1/";
		object.uploadsURL = "http://localhost/nixelsp/uploads/";
		break;
}

Object.freeze(object);
export default object;